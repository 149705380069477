<template>
  <div>
    <van-uploader
      v-model="fileList"
      multiple
      :accept="fileType"
      :after-read="afterRead"
      :before-delete="beforeDelete"
      :max-count="limit"
      :preview-size="previewSize"
    >
      <van-image width="100%" height="150" fit="contain" :src="background" />
    </van-uploader>
  </div>
</template>

<script>
  import { fileType } from '@/config'
  import { Toast } from 'vant'
  import { uploadFile } from '@/api/upload/upload'

  export default {
    name: 'UploadFile',
    props: {
      imgInfo: {
        type: Object,
        default: () => {},
      },
      previewSize: {
        type: Number,
        default: () => 80,
      },
    },
    data() {
      return {
        fileType,
        limit: 1,
        background: '',
        fileList: [], //原始文件
        pathList: [], //服务器返回路径
      }
    },
    mounted() {
      this.limit = this.imgInfo.multiple
      this.background = this.imgInfo.background
    },
    methods: {
      async afterRead(params) {
        let type = Object.prototype.toString.call(params)
        if (type === '[object Object]') {
          await this.pushImage(params)
        } else {
          if (params.length > 0) {
            for (let i = 0; i < params.length; i++) {
              let temp = params[i]
              await this.pushImage(temp)
            }
          }
        }
      },
      async pushImage(params) {
        const formData = new FormData()
        formData.append('file', params.file)
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { success, data } = await uploadFile(formData)
        Toast.clear()
        if (success) {
          this.pathList.push(data)
        } else {
          Toast('上传失败')
          this.fileList.pop()
        }
        this.$emit('fetch-data', this.pathList)
      },

      beforeDelete(file, index) {
        this.fileList.filter((item, indexs) => {
          if (index.index === indexs) {
            this.pathList.splice(indexs, 1)
          }
        })
        this.$emit('fetch-data', this.pathList)
        return true
      },

      render(imgInfo) {
        this.limit = imgInfo.multiple
        this.fileList = imgInfo.url
        this.pathList = imgInfo.url
      },
    },
  }
</script>

<style scoped>
  ::v-deep .van-uploader__preview {
    margin: auto;
  }
  ::v-deep .van-uploader__input-wrapper {
    margin: auto;
  }
</style>
