import { request } from '@/util/request'

export function uploadFile(data) {
  return request({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/api/Upload/PostFile',
    method: 'post',
    data,
  })
}
